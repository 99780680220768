

.range-slider {
    --thumb-color: #3490dc;
    --track-color: #9FC3C7;

    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 3px;
    background: var(--track-color);
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;

}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    z-index: 9999;
    width: 2rem;
    height: 2rem;
    border: 5px solid white;
    border-radius: 20px;
    background: #38A3BD;
    cursor: pointer;
    margin-top: -0.9rem;
    transition: background 0.2s;
}

.range-slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    background: var(--track-color);
    position: absolute;
    top: 50%;
    z-index: 0;
}

.last-child-point{
    height: 10px !important;
    width: 10px !important;
    border-radius: 50% !important;
    position: relative !important;
    top: 9px !important;
}

.first-child-point{
    height: 10px !important;
    width: 10px !important;
    border-radius: 50% !important;
    position: relative !important;
    top: 9px !important;
}

.image-container{
    transition: all 0.5s ease-in-out;
}
