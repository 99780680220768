@tailwind base;

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: blue;
  }
  
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }
  
  *::-webkit-scrollbar-track {
    background: var(--primary);
    border-radius: 5px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #0ea5e9;
    border-radius: 5px;
    border: 0px solid;
  }

@tailwind components;
@tailwind utilities;

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.background {
    background-image: url('../public/assets/background.jpeg');
    background-size: cover;
}

.blue-background {
    background-image: url('../public/assets/blue-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
